<template>
	<div style="width:60%; height: 100px;margin: auto" :id="id" ref="echarts"></div>
</template>

<script>
import * as echarts from "echarts";

require("echarts/theme/shine");

export default {
	name: "echartscom",
	props: ["id", "chartData", "nameData"],
	data() {
		return {
			arr: [],
			myChart: ""
		};
	},
	mounted() {
		if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
			this.myChart.dispose();
		}
		this.myChart = echarts.init(document.getElementById(this.id));
		this.drawCharts();
	},
	methods: {
		drawCharts() {
			let that = this;
			that.myChart.setOption({
				color: ["#0067A6", "#02F6FE", "#19D4AE", "#5AB1EF", "#2F89CF", "#dc5bf0", "#19b2d4"],
				tooltip: {
					trigger: "item",
					formatter:function(data){
						return data.name+ " : " + data.value + " ("+data.percent.toFixed(1)+"%)";
					}
				},
				series: [
					{
						type: "pie",
						radius: ['20%', '70%'],
						center: ["50%", "50%"],
						labelLine:{
							normal:{
								length:2
							}
						},
						//选中效果
						itemStyle: {
							emphasis: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						label: {
							position: "outer",
							normal: {
								// show:false,
								formatter: "{b}({d}%)", //设置引导线内容显示的格式
								textStyle: {
									color: "rgba(255,255,255,0.5)",
									fontSize: "12px"
								}
							},
							rich: {
								b: {
									fontSize: 12,
									lineHeight: 33,
								},
								//设置百分比数字颜色
								per: {
									color: "#00B4FB",
									fontSize: 12,
									padding: [2, 4],
									borderRadius: 2
								}
							}
						},
						width: "auto",
						// roseType: 'radius',
						animationType: "scale",
						animationEasing: "elasticOut",
						data: this.arr
					}
				]
			});
		},
		initData() {
			this.nameData.forEach((val, i) => {
				// 列数据
				this.chartData.forEach((item, index) => {
					if (i == index) {
						this.arr.push({
							value: item,
							name: val
						});
					}
				});
			});
		}
	},
	watch: {
		chartData: {
			// 在父组件数据发生改变后子组件要做到实时更新,就需要子组件用watch来监听数据的变化
			// 看情况是否需要newValue和oldValue之间值比较
			handler() {
				this.arr.length = 0;
				this.initData();
				this.drawCharts();
			},
			deep: true
		}
	},
	computed: {
		echarts() {
			return "echarts" + Math.random() * 100000;
		}
	},
	created() {
		this.initData();
	}
};
</script>

<style lang="scss" scoped>

</style>
